import $ from "jquery";
import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import "slick-carousel";
// import Scrollbar from "smooth-scrollbar";
import "magnific-popup";
import sal from "sal.js";

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.setHeroHeight();
    this.heroNav();
    this.addIOClass();
    this.initSlick();
    // this.initScrollbar();
    this.initSal();
    this.initCards();
    this.initNewsletterPopup();
    this.initFormPopup();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $("html");
    this.$body = $("body");
    this.$mainWrap = $("main");
    this.$header = $(".js-header");
    this.$mobileNav = $(".js-mobile-nav");
    this.$hamburger = $(".js-hamburger");
    this.$cookieNotice = $(".js-cookie-notice");
    this.$hero = document.querySelector(".hero-slider");
    this.$heroSlider = document.querySelector(".js-hero-set-height");
    this.$isPopupVisible = false;
  },
  events() {
    window.onload = () => {
      this.$body.removeClass("is-loading");
      this.$body.addClass("is-loaded");
    };

    setTimeout(() => {
      this.$body.removeClass("is-loading");
      this.$body.addClass("is-loaded");
    }, 5000);

    this.$doc.on("click", ".js-hamburger", (event) => this.showMobileNav(event));
    this.$doc.on("click", ".js-share", (event) => this.openShareWindow(event));
    this.$doc.on("click", ".js-popup-video", (event) => this.playVideoItem(event));
    this.$doc.on("click", ".js-accept-cookie", (event) => this.setCookieConsent(event));
    if (!!this.$heroSlider) this.$win.on("resize", () => this.setHeroHeight());
  },
  initFormPopup() {
    $(".js-popup-form").magnificPopup({
      type: "inline",
    });
  },
  initNewsletterPopup() {
    const throttledListener = throttle(scrollListener, 500);
    const olgaCookie = readCookie("olga_popup_seen");
    if (!olgaCookie && !$(".js-scroll-popup-off").length) {
      window.addEventListener("scroll", throttledListener);
    }

    function throttle(func, delay) {
      // allows [func] to run once every [delay] ms
      var func = func.bind(func),
        last = Date.now();
      return function () {
        if (Date.now() - last > delay) {
          func();
          last = Date.now();
        }
      };
    }
    function readCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }

    function scrollListener() {
      var threshold = document.body.clientHeight * 0.2;

      if (window.pageYOffset >= threshold) {
        $.magnificPopup.open({
          items: {
            src: "#newsletter-popup", // can be a HTML string, jQuery object, or CSS selector
            type: "inline",
          },
          callbacks: {
            close: function () {
              const now = new Date();
              const time = now.getTime();
              const expireTime = time + 60 * 60 * 24 * 1000 * 2;
              now.setTime(expireTime);
              document.cookie = "olga_popup_seen=1;expires=" + now.toUTCString() + ";path=/";
            },
          },
        });
        window.removeEventListener("scroll", throttledListener);
      }
    }
  },
  initSal() {
    const animations = sal({
      animateClassName: "is-visible",
    });
    if (this.isMobileDevice()) {
      this.$body.addClass("is-mobile");
      animations.disable();
    } else {
      this.$body.addClass("is-desktop");
    }
  },
  setCookieConsent(event) {
    event.preventDefault();
    document.cookie = "olga_cookie_accepted=1; expires=Thu, 18 Dec 2100 12:00:00 UTC; path=/";
    this.$cookieNotice.addClass("is-hidden");
  },
  isMobileDevice() {
    return (
      typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1
    );
  },
  initScrollbar() {
    const scrollbar = Scrollbar.init(document.querySelector("#scroll-content"), {
      damping: 0.1,
      renderByPixels: true,
    });

    this.$doc.on("click", ".js-scroll-to", (event) => scrollToSection(event));

    const scrollToSection = (event) => {
      event.preventDefault();
      const el = $(event.currentTarget);
      let section = el.attr("href");

      if (section && $(section).length) {
        scrollbar.scrollIntoView(document.querySelector(section), {
          alignToTop: true,
          onlyScrollIfNeeded: true,
          offsetTop: 100,
        });
      }
    };

    if (window.location.hash) {
      const hash = window.location.hash;
      scrollbar.scrollIntoView(document.querySelector(hash), {
        alignToTop: true,
        onlyScrollIfNeeded: true,
        offsetTop: 100,
      });
    }
  },
  initCards() {
    const cards = document.querySelectorAll(".js-card");
    if (cards.length > 0) {
      Array.prototype.forEach.call(cards, (card) => {
        let down,
          up,
          link = card.querySelector(".button");
        if (link) {
          card.onmousedown = () => (down = +new Date());
          card.onmouseup = () => {
            up = +new Date();
            if (up - down < 200) {
              link.click();
            }
          };
        }
      });
    }
  },
  setHeroHeight() {
    if (!!this.$heroSlider) {
      document.documentElement.style.setProperty("--hero-height", window.innerHeight + "px");
    }
  },
  addIOClass() {
    if (!!window.IntersectionObserver) {
      this.$body.addClass("supports-io");
    }
  },
  heroNav() {
    if (this.$hero && !!window.IntersectionObserver) {
      this.$body.addClass("has-hero");
      let observer = new IntersectionObserver(cb, {});
      observer.observe(this.$hero);
    }

    function cb(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          document.querySelector(".js-header").classList.add("header--hero");
        } else {
          document.querySelector(".js-header").classList.remove("header--hero");
        }
      });
    }
  },
  initSlick() {
    $(".js-hero-slider").slick({
      arrows: false,
      slidesToShow: 1,
      dots: true,
      speed: 1000,
      infinite: true,
      pauseOnHover: false,
      pauseOnFocus: false,
      cssEase: "ease-in-out",
      autoplay: true,
      autoplaySpeed: 11000,
      fade: true,
    });

    $(".js-video-slider").slick({
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      infinite: true,
      cssEase: "ease-in-out",
      variableWidth: true,
      variableHeight: true,
      speed: 1000,
    });
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr("href");
    if (link.substr(0, 6) != "mailto") {
      event.preventDefault();
      window.open(link, "", "width=600, height=400");
    }
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass("is-active");
    this.$hamburger.toggleClass("is-active");
    this.$body.toggleClass("is-locked");
    if (this.$mobileNav.hasClass("is-active")) {
      this.$hamburger.attr("aria-pressed", "true");
    } else {
      this.$hamburger.removeAttr("aria-pressed");
    }
  },
  playVideoItem(event) {
    event.preventDefault();
    const videoItem = $(event.currentTarget);
    const videoType = videoItem.data("video-type");
    const videoSrc = videoItem.data("video-url");

    if (videoType == "external") {
      $.magnificPopup.open({
        items: {
          src: videoItem.attr("href"),
          type: "iframe",
        },
        midClick: true,
        removalDelay: 100,
        mainClass: "popup--animate",
        iframe: {
          // markup: markup, // HTML markup of popup, `mfp-close` will be replaced by the close button
          patterns: {
            youtube: {
              index: "youtube.com",
              id: "v=",
              src: "//www.youtube.com/embed/%id%?rel=0&autoplay=1",
            },
            vimeo: {
              index: "vimeo.com/",
              id: "/",
              src: "//player.vimeo.com/video/%id%?autoplay=1",
            },
          },
        },
      });
    } else if (videoType == "internal") {
      $.magnificPopup.open({
        items: {
          src: "#video-popup",
          type: "inline",
        },
        midClick: true,
        removalDelay: 100,
        mainClass: "popup--animate",
        callbacks: {
          open: function () {
            $("html").css("margin-right", 0);
            $(this.content).find("source").attr("src", videoSrc);
            $(this.content).find("video")[0].load();
            $(this.content).find("video")[0].play();
          },
          close: function () {
            $(this.content).find("video")[0].load();
          },
        },
      });
    } else {
      return false;
    }
  },
};

Mangrove.init();
